import React from "react";
import img1 from "../assets/img/logo.png";

const RegPbgs = () => {
  return (
    <div className="coming-soon-container">
      {/* Logo on top-left */}
      <div className="top-left-logo">
        <img src={img1} alt="PBGS Logo" className="logo-top" />
      </div>

      {/* Content in the center */}
      <div className="content-center">
        <div className="center-logo">
          <img src={img1} alt="PBGS Logo" className="logo-center" />
        </div>

        <a
          href="https://portal.punebharatgayansamaj.com/"
          target="_blank"
          
        >
          <button className="register-button">
            Click Here for Franchise Registration
          </button>
        </a>
      </div>
    </div>
  );
};

export default RegPbgs;
