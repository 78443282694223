import logo from './logo.svg';
import './App.css';
import RegPbgs from './components/RegPbgs';

function App() {
  return (
    <div >
      <RegPbgs />
    </div>
  );
}

export default App;
